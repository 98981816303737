.policy-wrap {
  height: auto;
}
.settings-policy-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px;
  background: $color__white;
  & > div > a {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $color__lgrey_96;
    padding: 16px 0;

    // &:last-child {
    //   border: none;
    // }
    & > .left-area {
      display: flex;
      flex-direction: column;
      gap: 8px;
      & > div:last-child {
        color: $color__grey_75;
      }
    }
    & > .right-area {
      display: flex;
      align-items: center;
      color: $color__grey_75;
      & > svg {
        margin-top: 1px;
        & > path {
          fill: $color__grey_75;
        }
      }
    }
  }
  & > .settings-receive-reservation {
    flex-wrap: wrap;
    .time-list-wrapper {
      width: 100%;
      margin-top: 19px;
      display: flex;
      gap: 24px;
      flex-direction: column;
      & > .time-list-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 97px;
        & > div:first-child {
          flex-grow: 0;
          flex-shrink: 0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 8px;
          gap: 8px;

          width: 40px;
          height: 32px;

          background: $color__white;
          border: 1px solid $color__default;
          border-radius: 100px;
        }
        & > div:last-child {
          padding-right: 5px;
          word-break: keep-all;
        }
      }
    }
  }
  & > .settings-reset {
    color: $color__grey_66;
    text-decoration: underline;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 31px 0 20px;
  }
}
.settings-policy-detail-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  background: $color__white;
  & > div {
    display: flex;
    justify-content: space-between;
    // border-top: 1px solid $color__lgrey_96;
    padding: 24px 0 32px 0;

    &:last-child {
      border: none;
    }
    & > .left-area {
      display: flex;
      flex-direction: column;
      gap: 8px;
      flex-basis: 165px;
      & > div:last-child {
        color: $color__grey_75;
      }
    }
    & > .right-area {
      display: flex;
      align-items: center;
      & > div {
        color: $color__grey_75;
        &:last-child {
          color: $color__default;
        }
      }

      & > svg {
        margin-top: -1px;
        & > path {
          fill: $color__grey_75;
        }
      }
    }
    &.disabled {
      & > .left-area {
        & > div {
          color: $color__grey_75;
        }
      }
      & > .right-area {
        display: flex;
        align-items: center;
        & > div {
          color: $color__grey_75;
        }
      }
    }
  }

  .edit-btn-wrapper {
    @include font__body_sm;
    color: $color__grey_66;
    display: flex;
    align-items: center;
  }
}

.reservation-amount-input-wrapper {
  display: flex;
  align-items: baseline;
  column-gap: 10px;
}

.same-day-reservation-wrapper {
  padding: 0;

  .switch-area {
    padding-inline: 20px;
    border-bottom: 1px solid $color__lgrey_96;
  }
}

.deposit-policy-setting-wrapper {
  .switch-area {
    column-gap: 15px;
  }
  
  .left-area {
    flex: 1;
  }
}

.reservation-time-unit-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: $color__white;
  padding: 0 20px;
  &.modaled {
    position: fixed;
    top: 0;
    max-width: 480px;
    width: 100%;
    z-index: 8;
    bottom: 0;
    overflow: auto;
    & > .head {
      display: flex;
      width: 100%;
      height: 56px;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      flex-grow: 0;
      font-weight: 600;
      font-size: 19px;
      line-height: 24px;
      letter-spacing: 1px;
      color: $color__black;
    }
    .button-container {
      position: sticky;
      bottom: 0;
      display: flex;
      align-items: center;
      width: calc(100% + 40px);
      background: #ffffff;
      padding: 20px;
      border-top: 1px solid #e1e2e4;
      z-index: 6;
      box-sizing: border-box;
      margin-left: -20px;
      margin-right: -20px;
      & > button {
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        @include font__subtitle1_sb;

        &.prev {
          @include tblm-button-gray;
        }

        &.next {
          @include tblm-button-color(var(--primary-color-rgb));
          color: $color__white !important;
          padding: 0;
        }
      }
    }
  }
  & > div {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &.reservation-time-unit {
      padding: 34px 0;
      display: flex;
      align-items: center;
      & > .left-area {
        & > div:last-child {
          color: $color__default;
        }
      }
    }
    &.reservation-time-wrapper {
      display: flex;
      flex-direction: column;
      gap: 18px;
      padding: 24px 0px;
      border-top: 1px solid $color__lgrey_96;

      &:last-of-type {
        padding-bottom: 20px;
      }

      & > span{
        @include font__subtitle_sb;
      }
      & svg {
        min-width: 16px;
        & > path {
          fill: $color__default;
        }
      }
      &.holiday {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        & > div:nth-child(2) {
          color: $color__grey_66;
        }
        & > svg {
          margin-left: auto;
        }
      }
    }
  }
}
.reservation-time-select-wrapper {
  & > .time-select-header {
    position: sticky;
    padding: 16px 20px 0 20px;
    display: flex;
    top: 0;
    flex-direction: column;
    gap: 16px;
    background: $color__white;
    z-index: 1;

    & > label {
      border-bottom: 1px solid $color__lgrey_93;
      padding-bottom: 16px;
      padding-top: calc(16px + 8px);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }
  }
  & > .time-select-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: calc(24px + 8px) 20px;
    & > .time-select-item {
      display: flex;
      justify-content: space-between;
      & > label {
        display: flex;
        gap: 8px;
        justify-content: flex-start;
        align-items: center;
        word-break: keep-all;
        flex-basis: 104px;
        flex-shrink: 0;
        height: 40px;
      }
      & > .times {

        user-select: none;
        margin-left: 12px;
        width: 100%;
        height: 100%;
        display: flex;
        gap: 8px;
        align-items: flex-start;
        flex-wrap: wrap;
        & > label {
          flex: 1;
          height: 40px;
          min-width: 25%;
          & > input {
            display: none;
          }
          & > div {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $color__black;
            @include font__body_sm;
            border-radius: var(--textfield-radius);
            padding: 0px 8px;
          }
          & > input:not(:checked) + div {
            border: 1px solid $color__lgrey_89;
          }
          & > input:checked + div {
            background-color: $color__black;
            color: $color__white;
          }
        }
      }
      & > .time-button-wrapper:not(.modal-time-button-wrapper) {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        justify-content: flex-end;
        width: 100%;
        & > button {
          flex-grow: 1;
          height: 40px;
          color: $color__white;
          background: $color__default;
          font-weight: 400;
        }
      }
    }
  }
}

.receive-reservation-wrapper {
  .font__subtitle,  .tblm-rc, .font__body_sm_m, .font__body_sm {
    letter-spacing: 1px;
    min-width: calc(100% / 3 - 6px);
  }

  .tblm-button-normal {
    border-radius: 4px;
    background: var(--lgrey_96, #F4F4F4);
  }

  .tblm-button-normal:active {
    animation: press-down 0.4s forwards;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.12);
    transform: translateY(2px); 
  }
  
}

.week-content-wrapper {
  display: flex;
  flex-direction: column;
  & > .title {
    padding: 16px 0;
    display: flex;
    justify-content: center;
  }
  & > .content {
    display: flex;
    flex-direction: column;
    padding: 24px 20px;
    & > .week-select-item {
      width: 100%;
      border-bottom: 1px solid $color__lgrey_93;
      & > label {
        display: flex;
        padding: 16px 4px;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        cursor: pointer;
        input[readonly] + i{
          opacity: 0;
        }
      }
      &.active {
        & > label {
          & > span {
            color: var(--primary-color);
          }
          & > input,
          i {
            opacity: 0;
          }
        }
      }
      &.disabled {
        & > label {
          & > span {
            color: $color__lgrey_89;
          }
        }
      }
      // &:first-child {
      //   border: none;
      // }
    }
  }
}

.line_height_20 {
  line-height: 20px;
}

// .tutorial-checkbox {
//   box-sizing: border-box;
//   width: calc(100% + 40px);
//   border-radius: 20px;
//   & > ul {
//     margin: 0;
//     padding: 0;
//     color: $color__black;
//     overflow: overlay;
//     overflow: auto;
//     list-style: none;
//     & > li {
//       box-sizing: border-box;
//       border-top: 1px solid $color__lgrey_93;
//       &:first-child {
//         border-top: 0;
//       }
//       & > label {
//         height: 54px;
//         display: flex;
//         align-items: center;
//         flex-direction: row-reverse;
//         & > i {
//           margin-left: auto;
//         }
//       }
//     }
//   }
// }
